export const initialNumbersValue = (
   heightValue = 54,
   numbersLength = 24,
   value = null,
   skip = 0,
) => {
   const initialValue24hourFormat = [
      {
         number: '00',
         translatedValue: (heightValue * 2).toString(),
         selected: false,
      },
   ];

   const initialValue12hourFormat = [
      {
         number: '00',
         translatedValue: heightValue.toString(),
         selected: false,
         hidden: true,
      },
   ];
   const arrayOfSelectedValue =
      numbersLength === 13 ? initialValue12hourFormat : initialValue24hourFormat;

   let count = 0;
   if (skip === 0 || 1 % skip === 0) {
      arrayOfSelectedValue.push({
         number: '01',
         translatedValue: heightValue.toString(),
         selected: false,
      });
   } else {
      count = -heightValue;
   }

   for (let index = 0; index < 10; index++) {
      for (let j = 0; j < numbersLength; j++) {
         if (
            (index === 0 && j < 2) ||
            (numbersLength === 13 && j === 0) ||
            (skip !== 0 && j % skip !== 0)
         ) {
            continue;
         }
         if (index === 1 && j === value) {
            if (j.toString().length === 1) {
               arrayOfSelectedValue.push({
                  number: `0${j.toString()}`,
                  translatedValue: `-${count}`,
                  selected: true,
               });
            } else {
               arrayOfSelectedValue.push({
                  number: j.toString(),
                  translatedValue: `-${count}`,
                  selected: true,
               });
            }
            count += heightValue;
            continue;
         }
         if (j.toString().length === 1) {
            arrayOfSelectedValue.push({
               number: `0${j.toString()}`,
               translatedValue: `-${count}`,
               selected: false,
            });
         } else {
            arrayOfSelectedValue.push({
               number: j.toString(),
               translatedValue: `-${count}`,
               selected: false,
            });
         }

         count += heightValue;
      }
   }

   return arrayOfSelectedValue;
};

export const returnSelectedValue = (heightValue = 54, numbersLength = 24, skip = 0) => {
   const arrayOfSelectedValue = [
      {
         number: '00',
         translatedValue: (heightValue * 2).toString(),
         arrayNumber: 0,
      },
   ];

   let count = 0;

   if (skip === 0 || 1 % skip === 0) {
      arrayOfSelectedValue.push({
         number: '01',
         translatedValue: heightValue.toString(),
         arrayNumber: 1,
      });
   } else {
      count = -heightValue;
   }

   for (let index = 0; index < 10; index++) {
      for (let j = 0; j < numbersLength; j++) {
         if (
            (index === 0 && j < 2) ||
            (numbersLength === 13 && j === 0) ||
            (skip !== 0 && j % skip !== 0)
         ) {
            continue;
         }
         if (j.toString().length === 1) {
            arrayOfSelectedValue.push({
               number: `0${j.toString()}`,
               translatedValue: `-${count}`,
               selected: false,
            });
         } else {
            arrayOfSelectedValue.push({
               number: j.toString(),
               translatedValue: `-${count}`,
               selected: false,
            });
         }

         count += heightValue;
      }
   }
   return arrayOfSelectedValue;
};
