import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faClock } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import moment from "moment";
import c from "classnames";

import { TimePicker } from "./react-ios-time-picker/src";
import { enableBodyScroll, disableBodyScroll } from "../../util/scrollLock";
import useLocalStorage from "../../util/localStorageHook";

const observer = new MutationObserver((mutationsList) => {
  mutationsList.forEach((mutation) => {
    const targetNode = document.getElementsByClassName(
      "react-ios-time-picker-popup"
    )[0];
    if (mutation.type === "childList" && !document.body.contains(targetNode)) {
      enableBodyScroll();
    }
  });
});

observer.observe(document.body, { childList: true });

const incompleteTextRepresentationToDbFormat = (durationText) => {
  let parsedDuration = moment.duration(durationText, "h:mm");
  if (durationText.indexOf(":") === -1) {
    parsedDuration = moment.duration(durationText, "h");
  }
  if (durationText.endsWith(":")) {
    parsedDuration = moment.duration(durationText.slice(0, -1), "h");
  }

  return parsedDuration.format("d h:mm:ss", {
    useSignificantDigits: true,
    trim: false,
  });
};

const testIncompleteTextFormat = (text, maxHours = 999) => {
  let testText = text.trim();
  if (testText.indexOf(":") === -1) {
    testText = `${text}:`;
  }

  if (parseInt(testText.split(":")[0]) > maxHours - 1) {
    return false;
  }

  return /^\d{0,3}:([0-5][0-9]?)?$/.test(testText);
};

const ManualDurationInput = (props) => {
  const { disableBorderEnd, maxHours } = props;
  const [duration, setDuration] = useState(
    moment.duration(props.value, "d h:mm:ss").format("h:mm", {
      useSignificantDigits: true,
      trim: false,
    })
  );

  useEffect(() => {
    if (incompleteTextRepresentationToDbFormat(duration) !== props.value) {
      setDuration(
        moment.duration(props.value, "d h:mm:ss").format("h:mm", {
          useSignificantDigits: true,
          trim: false,
        })
      );
    }
  }, [props.value]);

  useEffect(() => {
    if (incompleteTextRepresentationToDbFormat(duration) !== props.value) {
      // console.log(incompleteTextRepresentationToDbFormat(duration))
      // console.log(props.value);
      // console.log("------")
      props.onChange(incompleteTextRepresentationToDbFormat(duration));
    }
  }, [duration]);

  return (
    <Form.Control
      type="text"
      value={duration}
      className={disableBorderEnd && "border-end-0 rounded-end-0"}
      onChange={(e) => {
        if (testIncompleteTextFormat(e.target.value, maxHours)) {
          setDuration(e.target.value);
        }
      }}
    />
  );
};

const DurationPicker = ({
  value,
  onChange,
  manualMode,
  manualModeOnly,
  maxHours,
}) => {
  const [inManualMode, setManualMode] = useLocalStorage(
    "duration-picker-manual-mode",
    manualMode
  );

  return (
    <div className="d-flex">
      {inManualMode || manualModeOnly ? (
        <ManualDurationInput
          disableBorderEnd={!manualModeOnly}
          value={value}
          maxHours={maxHours}
          onChange={onChange}
        />
      ) : (
        <TimePicker
          placeHolder="…"
          onOpen={() => {
            disableBodyScroll();
          }}
          onChange={(newValue) => {
            onChange(
              moment.duration(newValue, "hh:mm").format("d h:mm:ss", {
                useSignificantDigits: true,
                trim: false,
              })
            );
          }}
          value={moment.duration(value, "d h:mm:ss").format("hh:mm", {
            useSignificantDigits: true,
            trim: false,
          })}
          saveButtonText="Speichern"
          cancelButtonText="Verwerfen"
          inputClassName="bg-warning input-group"
          skipMinutes={15}
        />
      )}
      {/*{manualModeOnly || (
        <Button
          variant="secondary"
          className="rounded-start-0 border-0"
          onClick={() => setManualMode(!inManualMode)}
        >
          <FontAwesomeIcon icon={inManualMode ? faClock : faPen} />
        </Button>
      )}*/}
    </div>
  );
};

export default DurationPicker;
