import { useNavigate } from "react-router-dom";

import { deleteOne, getPermission } from "../../hooks";

import { DeleteModal } from "./deleteModal.js";

const SingleActions = ({ type, didChange, id, onSave, onDelete }) => {
  const navigate = useNavigate();

  const canDelete = getPermission(type, "delete");
  const canChange = getPermission(type, "change");
  const canAdd = getPermission(type, "add");
  const canEdit = id ? canChange : canAdd;

  // No longer supported
  // From https://reactrouter.com/en/main/hooks/use-blocker:
  // Blocking a user from navigating is a bit of an anti-pattern, so please carefully consider any usage of this hook and use it sparingly. In the de-facto use case of preventing a user navigating away from a half-filled form, you might consider persisting unsaved state to `sessionStorage` and automatically re-filling it if they return instead of blocking them from navigating away.
  // <Prompt
  //   when={didChange}
  //   message="Wirklich verlassen? Es gibt noch ungespeicherte Änderungen"
  // />
  return (
    <>
      <DeleteModal
        count={1}
        onConfirm={() => {
          navigate(`/${type}/`);
          deleteOne(type, id);
        }}
      />
      <div className="text-right">
        {didChange && id ? (
          <button
            className="btn btn-warning me-2"
            onClick={(e) => {
              e.preventDefault();
              if (onDelete) {
                onDelete();
              } else {
                // navigate(0);
              }
            }}
          >
            <i className="fa fa-trash" /> Änderungen Verwerfen
          </button>
        ) : (
          canDelete && (
            <button
              className="btn btn-danger me-2"
              data-bs-toggle="modal"
              data-bs-target="#deleteConfirmModal"
              onClick={(e) => {
                e.preventDefault();
                if (onDelete) {
                  onDelete();
                }
              }}
            >
              <i className="fa fa-trash" /> Löschen
            </button>
          )
        )}
        {canEdit && (
          <button
            className="btn btn-success"
            onClick={onSave}
            disabled={!didChange}
          >
            <i className="fa fa-save" /> Speichern
          </button>
        )}
      </div>
    </>
  );
};

export default SingleActions;
