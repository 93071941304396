import axios from "axios";
import _ from "lodash";

import config from "./config";

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withXSRFToken = ({ method }) => {
  return ["POST", "PUT", "PATCH", "DELETE"].indexOf(_.toUpper(method)) !== -1;
};

const api = (() => {
  return axios.create({
    baseURL: config.api_endpoint,
  });
})();

export const signOut = () => {
  window.location.href = "/logout";
};

api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (!err.response) {
      signOut();
    }
    const { status } = err.response;
    if (status === 401) {
      signOut();
    }

    if (status === 403) {
      alert("Nicht berechtigt");
    }

    return Promise.reject(err);
  }
);

export default api;
