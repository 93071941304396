import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import c from "classnames";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import BootstrapTable from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import DateTimeInput from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faChartSimple } from "@fortawesome/free-solid-svg-icons";

import _ from "lodash";

import SingleActions from "./resources/singleActions";
import Table from "./resources/table";
import DurationPicker from "./resources/durationPicker";

import { useOne } from "../hooks";

import config from "../util/config";
import api from "../util/api";

import Tracking, { Activity } from "./tracking";

const todayDate = moment(config.today, "dd, DD.MM.YY");

const EmployeeStatsTable = ({ data, year, week, title }) => {
  const entries = data.filter((entry) => {
    return (
      parseInt(entry.year) === parseInt(year) &&
      parseInt(week) === parseInt(entry.week)
    );
  });
  return (
    <>
      <small>{title}</small>
      {entries.length === 0 ? (
        <p>
          <small className="text-muted">Keine Einträge vorhanden</small>
        </p>
      ) : (
        <BootstrapTable striped borderless hover disabled size="sm">
          <thead>
            <tr>
              <th style={{ width: "30%" }}>Projekt</th>
              <th style={{ width: "45%" }}>Tätigkeiten</th>
              <th style={{ width: "25%" }}>Dauer</th>
            </tr>
          </thead>
          <tbody>
            {_.orderBy(entries, ["projectId"], ["desc"]).map((value, index) => (
              <tr
                key={index}
                style={value.projectId === -2 ? { fontWeight: "bold" } : {}}
              >
                <td>{value.projectName}</td>
                <td>{value.activities}</td>
                <td>{value.ammount}</td>
              </tr>
            ))}
          </tbody>
        </BootstrapTable>
      )}
    </>
  );
};

const EmployeeStats = ({ data }) => {
  const [year, setYear] = useState(parseInt(todayDate.year()));
  const [month, setMonth] = useState(parseInt(todayDate.month()) + 1);

  const monthStartDate = `${year}-${month.toString().padStart(2, "0")}-01`;

  let startDays = [];

  let firstDayOfMonth = moment(monthStartDate, "YYYY-MM-DD");
  const lasDayOfMonth = moment(monthStartDate, "YYYY-MM-DD").endOf("month");

  while (firstDayOfMonth.isBefore(lasDayOfMonth)) {
    startDays.push(firstDayOfMonth.format());
    firstDayOfMonth = firstDayOfMonth.add(1, "weeks");
  }

  const minYear = _.min(data.map((e) => parseInt(e.year)));
  const maxYear = _.max(data.map((e) => parseInt(e.year)));

  const years = _.union(_.range(minYear, maxYear + 1), todayDate.year());

  return (
    <>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Jahr</Form.Label>
          <Form.Select value={year} onChange={(e) => setYear(e.target.value)}>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Monat</Form.Label>
          <Form.Select
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            variant="info"
          >
            <option value={1}>Januar</option>
            <option value={2}>Februar</option>
            <option value={3}>März</option>
            <option value={4}>April</option>
            <option value={5}>Mai</option>
            <option value={6}>Juni</option>
            <option value={7}>Juli</option>
            <option value={8}>August</option>
            <option value={9}>September</option>
            <option value={10}>Oktober</option>
            <option value={11}>November</option>
            <option value={12}>Dezember</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <div className="mt-4">
        {startDays.map((startDay, index) => {
          return (
            <div className="p-3 border">
              <EmployeeStatsTable
                key={index}
                data={data}
                year={moment(startDay).weekday(0).year()}
                week={moment(startDay).isoWeek()}
                title={`
                ${moment(startDay).weekday(0).format("DD.MM.YY")} - ${moment(
                  startDay
                )
                  .weekday(6)
                  .format("DD.MM.YY")} (KW ${moment(startDay).isoWeek()})
              `}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

const EmployeeActivity = ({
  employeeName,
  employeeId,
  employeeStats,
  selectedActivityIds,
  onClose,
}) => {
  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showStats, setShowStats] = useState(false);

  const [bulkActivity, setBulkActivity] = useState({ id: 0 });
  const [bulkActivityLoad, setBulkActivityLoad] = useState(false);

  const handleCloseEdit = () => {
    onClose();
    setShowEdit(false);
  };
  const handleShowEdit = () => setShowEdit(true);

  const handleCloseStats = () => setShowStats(false);
  const handleShowStats = () => setShowStats(true);

  const handleShowBulkEdit = () => setShowBulkEdit(true);
  const handleCloseBulkEdit = () => setShowBulkEdit(bulkActivityLoad);

  return (
    <>
      <div>
        {selectedActivityIds.length > 0 && (
          <Button
            variant="warning"
            onClick={handleShowBulkEdit}
            className="me-1"
          >
            <FontAwesomeIcon icon={faPen} fixedWidth /> Stapelverarbeitung (
            {selectedActivityIds.length})
          </Button>
        )}
        <Button variant="warning" onClick={handleShowEdit} className="me-1">
          <FontAwesomeIcon icon={faPen} fixedWidth /> Arbeitszeiten einzeln
          anpassen
        </Button>
        <Button variant="light" onClick={handleShowStats}>
          <FontAwesomeIcon icon={faChartSimple} fixedWidth />{" "}
          Arbeitszeitstatistik anzeigen
        </Button>
      </div>

      <Modal show={showBulkEdit} onHide={handleCloseBulkEdit} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Arbeitszeiten {employeeName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Activity
            data={bulkActivity}
            bulkUpdate
            onUpdate={setBulkActivity}
            disabled={bulkActivityLoad}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="warning"
            onClick={(e) => {
              e.preventDefault();
              setBulkActivityLoad(true);

              Promise.all(
                selectedActivityIds.map((id) => {
                  return api.patch(`activity/${id}`, {
                    project: bulkActivity.project,
                    activityType: bulkActivity.activityType,
                    note: bulkActivity.note,
                  });
                })
              ).then(() => {
                setBulkActivityLoad(false);
                window.location.reload();
              });
            }}
            className="w-100"
          >
            {bulkActivityLoad ? (
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              <>Für {selectedActivityIds.length} Arbeitszeiteinträge updaten</>
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEdit} onHide={handleCloseEdit} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Arbeitszeiten {employeeName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Tracking embedded={true} employeeId={employeeId} />
        </Modal.Body>
        <Modal.Footer>
          <small className="text-danger">
            Arbeitszeiten werden direkt und ohne direktes abspeichern
            aktualisiert!
          </small>
        </Modal.Footer>
      </Modal>

      <Modal show={showStats} onHide={handleCloseStats} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Arbeitszeitstatistik {employeeName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EmployeeStats data={employeeStats} />
        </Modal.Body>
      </Modal>
    </>
  );
};

const EmployeeChangePassword = ({
  employeeId,
  forceShow,
  forceSuccessMessage,
  onUpdate,
}) => {
  const [show, setShow] = useState(forceShow);
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [errorMessages, setErrorMessages] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    setShow(forceShow);
  }, [forceShow]);

  const handleShow = () => setShow(true);

  const onSubmit = (e) => {
    e.preventDefault();
    api
      .put(`employee/${employeeId}/password`, { password })
      .then((res) => {
        setSuccess(true);
        setErrorMessages(false);
        onUpdate && onUpdate();
      })
      .catch(({ response }) => {
        setSuccess(false);
        if (response.data.status === "password_validation_failed") {
          setErrorMessages(response.data.helpTexts);
        } else {
          setErrorMessages([
            "Unbekannter Fehler, Passwort konnte nicht geändert werden!",
          ]);
        }
      });
  };

  return (
    <>
      <Form.Group as={Col}>
        <Form.Label>Passwort</Form.Label>
        <InputGroup controlId="validationCustom02">
          <Form.Control type="password" value={"###########"} disabled />
          <Button
            variant="warning"
            onClick={handleShow}
            className="me-1"
            disabled={employeeId === undefined}
          >
            Passwort ändern?
          </Button>
        </InputGroup>
        {employeeId === undefined && (
          <div className="text-danger mt-2">
            Passwort kann erst nach abspeichern des Mitarbeiters festgelegt
            werden!
          </div>
        )}
      </Form.Group>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Passwort ändern</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessages && (
            <Alert variant="danger">
              Passwort konnte nicht geändert werden:
              <ul className="mb-0">
                {errorMessages.map((errorMessage, key) => {
                  return <li key={key}>{errorMessage}</li>;
                })}
              </ul>
            </Alert>
          )}

          {(success || forceSuccessMessage) && (
            <Alert variant="success">
              {forceSuccessMessage || "Passwort wurde erfolgreich geändert!"}
            </Alert>
          )}

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextPassword"
          >
            <Form.Label column sm="5">
              Passwort
            </Form.Label>
            <Col sm="7">
              <Form.Control
                value={password}
                onChange={(e) => {
                  e.preventDefault();
                  setPassword(e.target.value);
                }}
                type="password"
                placeholder="Password"
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextPassword"
          >
            <Form.Label column sm="5">
              Passwort wiederholen
            </Form.Label>
            <Col sm="7">
              <Form.Control
                value={passwordRepeat}
                onChange={(e) => {
                  e.preventDefault();
                  setPasswordRepeat(e.target.value);
                }}
                type="password"
                placeholder="Password"
              />
            </Col>
          </Form.Group>
          <Button
            variant="warning"
            className="w-100"
            disabled={password !== passwordRepeat || password.length === 0}
            onClick={onSubmit}
          >
            {password !== passwordRepeat
              ? "Passwörter stimmen nicht überein."
              : "Passwort ändern."}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const EmployeeSingle = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [selectedActivityIds, setSelectedActivityIds] = useState([]);
  const [activityKey, setActivityKey] = useState(Date.now());
  const [updatePasswordState, setUpdatePasswordState] = useState(false);

  const [
    data,
    setData,
    update,
    didChange,
    formProps,
    errorText,
    initialData,
  ] = useOne("employee", params.id, (createdData) => {
    // Employee Created
    navigate(`/employee/single/${createdData.id}`, { replace: true });
    setUpdatePasswordState({
      show: true,
      message:
        "Mitarbeiter erfolgreich erstellt. Bitte legen Sie nun ein Passwort fest für den Mitarbeiter!",
    });
  });

  const onSubmit = (e) => {
    e.preventDefault();
    update();
  };

  return (
    <>
      <Form noValidate onSubmit={onSubmit}>
        <Row>
          <Col>
            <h1>
              Mitarbeiter{" "}
              <small className="text-muted ms-2">
                {data.firstName} {data.lastName}
              </small>
            </h1>
          </Col>
          <Col className="text-end">
            <SingleActions
              type="employee"
              didChange={didChange}
              id={params.id}
              onSave={onSubmit}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={5}>
            <Form.Group>
              <Form.Label>Vorname</Form.Label>
              <Form.Control type="text" {...formProps("firstName")} />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group>
              <Form.Label>Nachname</Form.Label>
              <Form.Control type="text" {...formProps("lastName")} />
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group>
              <Form.Label>Wochenarbeitszeit (Stunden:Minuten)</Form.Label>
              <DurationPicker
                maxHours={100}
                onChange={(value) => {
                  setData({
                    ...data,
                    weeklyExpectedActivity: value,
                  });
                }}
                value={data["weeklyExpectedActivity"]}
                manualModeOnly={true}
              />
              <Form.Control.Feedback type="invalid">
                Arbeitszeit muss im Format Stunden:Minuten sein.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Nutzername</Form.Label>
            <Form.Control type="text" {...formProps("username")} />
            <Form.Control.Feedback type="invalid">
              Nutzername darf nicht leer sein.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>E-Mail</Form.Label>
            <Form.Control type="email" {...formProps("email")} />
            <Form.Control.Feedback type="invalid">
              {errorText("email")}
            </Form.Control.Feedback>
          </Form.Group>

          <EmployeeChangePassword
            employeeId={data.id}
            forceShow={updatePasswordState?.show}
            forceSuccessMessage={updatePasswordState?.message}
            onUpdate={() => setUpdatePasswordState(false)}
          />
        </Row>
      </Form>
      {data.id && (
        <Row className="mt-4">
          <Col xs={12}>
            <div className="d-flex justify-content-between">
              <Form.Label>Efasste Arbeitszeiten</Form.Label>
              <EmployeeActivity
                selectedActivityIds={selectedActivityIds}
                employeeId={data.id}
                onClose={() => {
                  setActivityKey(Date.now());
                }}
                employeeStats={data.activityStats || []}
                employeeName={
                  <small className="text-muted ms-2">
                    {data.firstName} {data.lastName}
                  </small>
                }
              />
            </div>

            <div key={activityKey}>
              {/*TODO: Might not show logged inactive projects */}
              {data.id && (
                <Table
                  embedded={true}
                  noSearch
                  type="activity"
                  orderBy="date"
                  order="0"
                  onSelectedIds={(ids) => {
                    setSelectedActivityIds(ids);
                  }}
                  columns={{
                    date: "Datum",
                    duration: "Dauer",
                    project: "Projekt",
                    activityType: "Tätigkeit",
                    note: "Bemerkung",
                  }}
                  mapEntries={{
                    date: ({ date }) =>
                      moment(date, "YYYY-MM-DD").format("dd, DD.MM.YY"),
                    project: ({ project }) => {
                      const projectEntry = config[
                        "available_project_choices"
                      ].filter(({ id }) => project === id)[0];
                      return projectEntry ? projectEntry.name : "-";
                    },
                    activityType: ({ activityType }) => {
                      const activityTypeEntry = config[
                        "available_activity_type_choices"
                      ].filter(({ id }) => activityType === id)[0];
                      return activityTypeEntry ? activityTypeEntry.name : "-";
                    },
                    duration: ({ duration }) => {
                      return `${moment
                        .duration(duration, "d h:mm:ss")
                        .format("h:mm", { trim: false })} h`;
                    },
                  }}
                  additionalSearchParams={{
                    employee: data.id,
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export const EmployeeList = ({
  embedded,
  onSingleSelect,
  additionalSearchParams,
}) => {
  return (
    <>
      {!embedded && <h1>Mitarbeiter</h1>}
      <Table
        embedded={embedded}
        type="employee"
        singleRoute={onSingleSelect ? undefined : "/employee/single"}
        resourceTitle="Mitarbeiter"
        onSingleSelect={onSingleSelect}
        orderBy="last_name"
        order="1"
        perPage={100}
        columns={{
          id: "#",
          username: "Nutzername",
          firstName: "Vorname",
          lastName: "Nachname",
          email: "E-Mail",
          weeklyExpectedActivity: "Wochenarbeitszeit",
        }}
        mapEntries={{
          weeklyExpectedActivity: ({ weeklyExpectedActivity }) =>
            moment
              .duration(weeklyExpectedActivity, "d h:mm:ss")
              .format("h:mm", { trim: false }),
        }}
        additionalSearchParams={additionalSearchParams || {}}
      />
    </>
  );
};
