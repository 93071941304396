export const DeleteModal = ({ count, onConfirm }) => {
  return (
    <>
      <div className="modal fade" id="deleteConfirmModal" tabIndex="-1">
        <div className="modal-dialog modal-xs">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title">
                {count > 1 ? "Einträge" : "Eintrag"} wirklich löschen?
              </h5>
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <button
                  className="btn btn-secondary w-50 me-2"
                  data-bs-dismiss="modal"
                >
                  Abbrechen
                </button>
                <button
                  className="btn btn-danger w-50 ms-2"
                  data-bs-dismiss="modal"
                  onClick={onConfirm}
                >
                  Löschen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
