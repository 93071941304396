import React from "react";
import { createRoot } from "react-dom/client";
import moment from "moment";
import "moment/locale/de";
import momentDurationFormatSetup from "moment-duration-format";
import "./index.scss";

import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";

import App from "./app";

momentDurationFormatSetup(moment);
moment.locale("de");

const rootElement = document.getElementById("react-root");

if (rootElement !== null) {
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
