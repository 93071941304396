import { useState, useEffect } from "react";
import _ from "lodash";

import api from "../util/api";

import getPermission from "./permission";

const removeUndefined = (obj) =>
  _.omit(
    obj,
    _.filter(_.keys(obj), function (key) {
      return _.isUndefined(obj[key]);
    })
  );

export const getOne = async (type, id) => {
  const { data } = await api.get(`${type}/${id}`);
  return data;
};

const didChangeWithoutId = (obj1, obj2) => {
  const clonedObj1 = { ...obj1 };
  const clonedObj2 = { ...obj2 };

  delete clonedObj1["id"];
  delete clonedObj2["id"];

  return JSON.stringify(clonedObj1) !== JSON.stringify(clonedObj2);
};

export const useOne = (type, id, onCreate) => {
  const [initialEntity, setInitialEntity] = useState({});
  const [entity, setEntity] = useState({});

  const [error, setError] = useState({});

  const udpateEntity = (data) => {
    setEntity(data);
    setInitialEntity(data);
  };

  const handleErrors = ({ response }) => {
    try {
      let errObj = {};
      Object.entries(response.data).forEach(([key, message]) => {
        errObj[key] = message;
      });
      setError(errObj);
    } catch (err) {
      alert("Ups! Es ist etwas schief gelaufen!");
      setError({});
    }
  };

  useEffect(() => {
    if (id) {
      api.get(`${type}/${id}`).then(({ data }) => {
        udpateEntity(data);
      });
    } else {
      udpateEntity({});
    }
  }, [type, id]);

  const update = () => {
    // Replace undefined values with explicit null entries
    let entityReqData = {};
    Object.entries(entity).forEach(([key, message]) => {
      entityReqData[key] = message === undefined ? null : message;
    });
    if (entity.id) {
      return new Promise((resolve, reject) => {
        api
          .patch(`${type}/${id}`, entityReqData)
          .then(({ data }) => {
            udpateEntity(data);
            resolve(data);
          })
          .catch((err) => {
            handleErrors(err);
            reject(err);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        api
          .post(type, entityReqData)
          .then(({ data }) => {
            udpateEntity(data);
            resolve(data);
            onCreate(data);
          })
          .catch((err) => {
            handleErrors(err);
            reject(err);
          });
      });
    }
  };

  const formProps = (name) => ({
    onChange: (e) => {
      e.preventDefault();
      const { value } = e.target;
      setEntity({ ...entity, [name]: value !== "" ? value : undefined });
    },
    value: entity[name],
    isInvalid: !!error[name],
  });

  const errorText = (name) => {
    return error[name];
  };

  return [
    entity,
    setEntity,
    update,
    didChangeWithoutId(initialEntity, entity),
    formProps,
    errorText,
    initialEntity,
  ];
};

export const deleteOne = async (type, id) => {
  return await api.delete(`${type}/${id}`);
};

export const deleteMany = async (type, ids) => {
  return Promise.all(ids.map((id) => deleteOne(type, id)));
};

export const getMany = async (type, searchParams) => {
  const urlSearchString = new URLSearchParams(
    removeUndefined({
      offset: isNaN(searchParams.perPage * searchParams.page)
        ? 0
        : searchParams.perPage * searchParams.page,
      limit: searchParams.perPage,
      search: searchParams.search,
      ordering: `${searchParams.order === -1 ? "-" : ""}${_.snakeCase(
        searchParams.orderBy
      )}`,
      ...searchParams.additionalSearchParams,
    })
  ).toString();

  const { data } = await api.get(`${type}?${urlSearchString}`);
  return [data.results, data.count];
};

export const useMany = (type, initialSearchParams) => {
  const [searchParams, setSearchParams] = useState({
    page: 0,
    perPage: 10,
    order: -1,
    orderBy: "updatedAt",
    ...(initialSearchParams || {}),
  });

  const [many, setMany] = useState({
    entries: [],
    count: 0,
    loading: false,
  });

  useEffect(() => {
    setMany({
      ...many,
      loading: true,
    });

    getMany(type, searchParams).then(([results, count]) => {
      setMany({
        entries: results,
        count,
        loading: false,
      });
    });
  }, [type, searchParams]);

  return [
    many.entries,
    many.count,
    searchParams,
    setSearchParams,
    many.loading,
  ];
};

export { getPermission };
