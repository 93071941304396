import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import c from "classnames";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import BootstrapTable from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faTimes,
  faCheck,
  faPen,
  faChartSimple,
} from "@fortawesome/free-solid-svg-icons";

import _ from "lodash";

import config from "../util/config";

import SingleActions from "./resources/singleActions";
import Table from "./resources/table";

import useLocalStorage from "../util/localStorageHook";

import { useOne } from "../hooks";

const ProjectCalculationTable = ({ items, didChange }) => {
  const totalDurationHours = _.floor(_.sumBy(items, "hoursRaw"));
  const totalHoursMinutes = _.round(
    (_.floor(_.sumBy(items, "hoursRaw"), 2) - totalDurationHours) * 60
  );
  const totalDuration = `${totalDurationHours}:${totalHoursMinutes} h`;
  const totalSum = `${_.round(_.sumBy(items, "sumRaw"), 2)
    .toString()
    .replace(".", ",")} €`;

  return didChange ? (
    <div class="alert alert-light" role="alert">
      Bitte speichern Sie das Projekt, um die Kalkulationsübersicht anzuzeigen.
    </div>
  ) : (
    <BootstrapTable striped bordered hover disabled>
      <thead>
        <tr>
          <th>Posten</th>
          <th>Menge</th>
          <th>Stückkosten</th>
          <th>Summe</th>
        </tr>
      </thead>
      <tbody>
        {Object.values(items).map((value, index) => (
          <tr key={index}>
            <td>{value.name}</td>
            <td>{value.ammount}</td>
            <td>{value.itemCost}</td>
            <td>{value.sum}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td>
            <strong>Gesamt</strong>
          </td>
          <td>{totalDuration}</td>
          <td>-</td>
          <td>
            <strong>{totalSum}</strong>
          </td>
        </tr>
      </tfoot>
    </BootstrapTable>
  );
};

const ActivityTypes = ({
  value,
  onChange,
  remainingActivitiyUnitCost,
  setRemainingActivitiyUnitCost,
}) => {
  const update = (index, updateValues) => {
    onChange(
      value.map((activityType, i) => {
        if (index === i) {
          return {
            ...activityType,
            ...updateValues,
          };
        }
        return activityType;
      })
    );
  };

  return (
    <Form.Group>
      <Form.Label>Projekttätigkeiten</Form.Label>

      <InputGroup className="mb-2" style={{ paddingRight: 39 }}>
        <Form.Control value="Sonstige" disabled />
        <Form.Control
          type="number"
          min="0"
          step=".01"
          value={remainingActivitiyUnitCost}
          style={{ maxWidth: 215 }}
          placeholder="Verrechnungsschlüssel"
          onChange={(e) => {
            e.preventDefault();
            const ammount = e.target.value;
            if (/^\d*((,|.)\d{1,2})?$/.test(ammount)) {
              setRemainingActivitiyUnitCost(ammount);
            }
          }}
        />
        <InputGroup.Text>€</InputGroup.Text>
      </InputGroup>

      {value.map((activityType, index) => (
        <div className="d-flex mb-2" key={index}>
          <InputGroup className="mt-2">
            <Form.Control
              value={activityType.name}
              placeholder="Bezeichnung"
              onChange={(e) => update(index, { name: e.target.value })}
            />
            <Form.Control
              type="number"
              min="0"
              step=".01"
              value={activityType.unitCost}
              style={{ maxWidth: 215 }}
              placeholder="Verrechnungsschlüssel"
              onChange={(e) => {
                e.preventDefault();
                const ammount = e.target.value;
                if (/^\d*((,|.)\d{1,2})?$/.test(ammount)) {
                  update(index, { unitCost: ammount });
                }
              }}
            />
            <InputGroup.Text>€</InputGroup.Text>
            {/*<Form.Control as="textarea" rows="1" value={activityType.description} placeholder="Kurzbeschreibung"/>*/}
          </InputGroup>
          <Button
            variant="danger"
            size="sm"
            className="mt-2 ms-1"
            onClick={(e) => {
              e.preventDefault();
              onChange(value.filter((activityType, i) => index !== i));
            }}
          >
            <FontAwesomeIcon icon={faTrash} fixedWidth />
          </Button>
        </div>
      ))}

      <Button
        variant="primary"
        className="w-100 mt-2"
        size="sm"
        onClick={() => {
          onChange(value.concat({ activityTypeId: 0 }));
        }}
      >
        <FontAwesomeIcon icon={faPlus} fixedWidth />
      </Button>
    </Form.Group>
  );
};

const ProjectExpenditures = ({ value, onChange }) => {
  const [show, setShow] = useState(false);

  const update = (index, updateValues) => {
    onChange(
      value.map((expenditure, i) => {
        if (index === i) {
          return {
            ...expenditure,
            ...updateValues,
          };
        }
        return expenditure;
      })
    );
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Form.Group>
      <Form.Label>Materialkosten</Form.Label>
      <div className="d-flex pb-2">
        <small className="pe-1">
          <strong>Erfasst:</strong>
        </small>
        <small>
          {value
            .map((expenditure, index) => {
              if (!expenditure.name || /^\s+$/.test(expenditure.name)) {
                return "∅";
              } else {
                return expenditure.name;
              }
            })
            .join(", ")}
        </small>
      </div>
      <Button variant="warning" onClick={handleShow} className="w-100">
        <FontAwesomeIcon icon={faPen} fixedWidth /> Materialkosten anpassen
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Materialkosten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ overflow: "scroll", maxHeight: "80vh" }}>
            {value.map((expenditure, index) => (
              <div className="d-flex mb-2" key={index}>
                <div className="flex-grow-1">
                  <InputGroup className="mt-2">
                    <Form.Control
                      value={expenditure.date}
                      type="date"
                      style={{ maxWidth: 140 }}
                      onChange={(e) => {
                        e.preventDefault();
                        update(index, { date: e.target.value });
                      }}
                    />
                    <Form.Control
                      value={expenditure.name}
                      placeholder="Bezeichnung"
                      onChange={(e) => {
                        e.preventDefault();
                        update(index, { name: e.target.value });
                      }}
                    />
                    <Form.Control
                      type="number"
                      min="0"
                      step=".01"
                      value={expenditure.nettoEuroSum}
                      placeholder="Materialkosten (netto)"
                      onChange={(e) => {
                        e.preventDefault();
                        const ammount = e.target.value;
                        if (/^\d*((,|.)\d{1,2})?$/.test(ammount)) {
                          update(index, { nettoEuroSum: ammount });
                        }
                      }}
                    />
                    {/*<Form.Control as="textarea" rows="1" value={expenditure.description} placeholder="Kurzbeschreibung"/>*/}
                    <InputGroup.Text>€</InputGroup.Text>
                  </InputGroup>
                  <Form.Group className="mt-1">
                    <Form.Control
                      as="textarea"
                      placeholder="Bemerkungen"
                      rows="1"
                      value={expenditure.note}
                      onChange={(e) => {
                        e.preventDefault();
                        update(index, { note: e.target.value });
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="ms-1">
                  <Button
                    size="sm"
                    className="mt-2"
                    variant="danger"
                    onClick={(e) => {
                      e.preventDefault();
                      onChange(value.filter((expenditure, i) => index !== i));
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} fixedWidth />
                  </Button>
                </div>
              </div>
            ))}

            <Button
              variant="primary"
              className="w-100 mt-2"
              size="sm"
              onClick={() => {
                onChange(value.concat({}));
              }}
            >
              <FontAwesomeIcon icon={faPlus} fixedWidth />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Form.Group>
  );
};

const EmployeeName = ({ id }) => {
  const [data] = useOne("employee", id);

  if (data.id) {
    return `${data.firstName} ${data.lastName}`;
  } else {
    return (
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    );
  }
};

const ProjectActivity = ({ id }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  return (
    <Form.Group>
      <Form.Label>Erfasste Arbeitszeiteinträge</Form.Label>
      <br />
      <Button variant="light" onClick={handleShow} className="w-100">
        <FontAwesomeIcon icon={faChartSimple} fixedWidth /> Einträge einsehen
      </Button>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Arbeitszeiteinträge</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Table
              embedded={true}
              noSearch
              onSingleSelect={(id, activity) => {
                navigate(`/employee/single/${activity.employee}`);
              }}
              type="activity"
              orderBy="date"
              order="0"
              columns={{
                employee: "Mitarbeiter",
                date: "Datum",
                duration: "Dauer",
                activityType: "Tätigkeit",
                note: "Bemerkung",
              }}
              mapEntries={{
                date: ({ date }) =>
                  moment(date, "YYYY-MM-DD").format("dd, DD.MM.YY"),
                activityType: ({ activityType }) => {
                  const activityTypeEntry = config[
                    "available_activity_type_choices"
                  ].filter(({ id }) => activityType === id)[0];
                  return activityTypeEntry ? activityTypeEntry.name : "-";
                },
                employee: ({ employee }) => <EmployeeName id={employee} />,
                duration: ({ duration }) => {
                  return `${moment
                    .duration(duration, "d h:mm:ss")
                    .format("h:mm", { trim: false })} h`;
                },
              }}
              additionalSearchParams={{
                project: id,
              }}
            />
          </>
        </Modal.Body>
      </Modal>
    </Form.Group>
  );
};

export const ProjectSingle = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData, update, didChange, formProps] = useOne(
    "project",
    params.id,
    (newProject) => {
      navigate(`/project/single/${newProject.id}`, { replace: true });
    }
  );

  // Prefill activity types
  useEffect(() => {
    if (data.id === undefined && data.activityTypes === undefined) {
      setData({
        ...data,
        activityTypes: [
          { activityTypeId: 0, name: "Planung", unitCost: "65.00" },
          { activityTypeId: 0, name: "Fertigung", unitCost: "65.00" },
          { activityTypeId: 0, name: "Montage", unitCost: "65.00" },
        ],
      });
    }
  }, [data]);

  const onSubmit = (e) => {
    e.preventDefault();
    update().then(() => {
      // Simple call to reload the config for adjusted available projects
      if (data.id) {
        window.location.reload();
      }
    });
  };

  return (
    <Form noValidate onSubmit={onSubmit}>
      <Row>
        <Col>
          <h1>
            Projekt <small className="text-muted ms-2">{data.name}</small>
          </h1>
        </Col>
        <Col className="text-end">
          <SingleActions
            type="project"
            didChange={didChange}
            id={params.id}
            onSave={onSubmit}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={7}>
          <Row>
            <Col xs={7} className="mb-3">
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" {...formProps("name")} />
              </Form.Group>
            </Col>
            <Col xs={3} className="mb-3">
              <Form.Group>
                <Form.Label>Aufragseingang</Form.Label>
                <Form.Control type="date" {...formProps("orderReceived")} />
              </Form.Group>
            </Col>
            <Col xs={2} className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Projektstatus</Form.Label>
                <Form.Select {...formProps("status")}>
                  <option value="0">Nicht begonnen</option>
                  <option value="1">In Planung</option>
                  <option value="2">Aktiv</option>
                  <option value="3">Abgeschlossen</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Label>Projektbeschreibung/-bemerkungen</Form.Label>
                <Form.Control as="textarea" {...formProps("description")} />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <ActivityTypes
                remainingActivitiyUnitCost={data.remainingActivitiyUnitCost}
                setRemainingActivitiyUnitCost={(ammount) => {
                  setData({
                    ...data,
                    remainingActivitiyUnitCost: ammount,
                  });
                }}
                value={data.activityTypes || []}
                onChange={(activityTypes) => {
                  setData({
                    ...data,
                    activityTypes,
                  });
                }}
              />
            </Col>
            <Col xs={6}>
              <ProjectExpenditures
                value={data.projectExpenditures || []}
                onChange={(projectExpenditures) => {
                  setData({
                    ...data,
                    projectExpenditures,
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={5}>
          <ProjectActivity id={data.id} />
          <Form.Label className="mt-3">Projektkalkulation</Form.Label>
          <ProjectCalculationTable
            items={data.projectCalculation || []}
            didChange={didChange}
          />
        </Col>
      </Row>
    </Form>
  );
};

export const ProjectList = ({
  embedded,
  onSingleSelect,
  additionalSearchParams,
}) => {
  const [
    includedStatus,
    setIncludedStatus,
  ] = useLocalStorage("project-default-search-params", [0, 1, 2]);

  const toggleIncludedStatus = (e, status) => {
    e.preventDefault();
    if (includedStatus.includes(status)) {
      setIncludedStatus(includedStatus.filter((s) => s !== status));
    } else {
      setIncludedStatus(includedStatus.concat([status]));
    }
  };

  const searchParams = {
    status__in: includedStatus,
    ...additionalSearchParams,
  };

  return (
    <div key={JSON.stringify(searchParams)}>
      {!embedded && <h1>Projekte</h1>}
      <Table
        embedded={embedded}
        type="project"
        singleRoute={onSingleSelect ? undefined : "/project/single"}
        resourceTitle="Projekte"
        onSingleSelect={onSingleSelect}
        orderBy="id"
        order="1"
        perPage={100}
        columns={{
          id: "#",
          name: "Bezeichnung",
          orderReceived: "Aufragseingang",
          status: "Projektstatus",
        }}
        mapEntries={{
          orderReceived: ({ orderReceived }) =>
            moment(orderReceived).format("DD.MM.YYYY"),
          status: ({ status }) => {
            let statusText = "Nicht begonnen";
            statusText = parseInt(status) === 1 ? "In Planung" : statusText;
            statusText = parseInt(status) === 2 ? "Aktiv" : statusText;
            statusText = parseInt(status) === 3 ? "Abgeschlossen" : statusText;
            return statusText;
          },
        }}
        additionalSearchParams={searchParams}
        additionalActions={
          <Dropdown>
            <Dropdown.Toggle
              id="activity-settings"
              variant="secondary"
              className="ms-2 rounded-0"
            >
              Status
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => toggleIncludedStatus(e, 0)}>
                <FontAwesomeIcon
                  icon={faCheck}
                  fixedWidth
                  style={{ opacity: includedStatus.includes(0) ? 1 : 0 }}
                />{" "}
                Nicht begonnen
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => toggleIncludedStatus(e, 1)}>
                <FontAwesomeIcon
                  icon={faCheck}
                  fixedWidth
                  style={{ opacity: includedStatus.includes(1) ? 1 : 0 }}
                />{" "}
                In Planung
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => toggleIncludedStatus(e, 2)}>
                <FontAwesomeIcon
                  icon={faCheck}
                  fixedWidth
                  style={{ opacity: includedStatus.includes(2) ? 1 : 0 }}
                />{" "}
                Aktiv
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => toggleIncludedStatus(e, 3)}>
                <FontAwesomeIcon
                  icon={faCheck}
                  fixedWidth
                  style={{ opacity: includedStatus.includes(3) ? 1 : 0 }}
                />{" "}
                Abgeschlossen
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        }
      />
    </div>
  );
};
