export const enableBodyScroll = () => {
  document.body.classList.remove("modal-open");
  document.body.style.removeProperty("overflow");
  document.body.style.removeProperty("paddingRight");
};

export const disableBodyScroll = () => {
  document.body.classList.add("modal-open");
  document.body.style.overflow = "hidden";
  document.body.style.paddingRight = "0px";
};
