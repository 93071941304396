import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
  faPlus,
  faChevronLeft,
  faChevronRight,
  faEllipsisVertical,
  faArrowUp,
  faArrowDown,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";
import { useNetworkState } from "@uidotdev/usehooks";

import moment from "moment";
import _ from "lodash";

import config from "../util/config";
import { enableBodyScroll, disableBodyScroll } from "../util/scrollLock";
import api from "../util/api";

import { useMany } from "../hooks";
import getPermission from "../hooks/permission";
import DurationPicker from "./resources/durationPicker";
import "./tracking.scss";

export const Activity = (props) => {
  const { data, onRemove, onUpdate, embedded, disabled, bulkUpdate } = props;
  const { id, duration, note, activityType, project } = data;

  const [selected, setSelected] = useState(false);
  // const [duration, setDuration] = useState(props.duration);

  // const update = (data) => {
  //   onUpdate({

  //   })
  // }

  const projectOptions = _.sortBy(
    config["available_project_choices"]
      .filter(({ id, status }) => status === 2 || project === id)
      .map(({ id, name, status }) => ({ value: id, label: name })),
    [(project) => project?.label?.toLowerCase()],
    ["asc"]
  );

  const activityOptions = [{ value: "0", label: "Sonstige" }].concat(
    _.sortBy(
      config["available_activity_type_choices"]
        .filter(({ project_id }) => project_id === project)
        .map(({ id, name, project_id }) => ({ value: id, label: name })),
      [(activity) => activity?.label?.toLowerCase()],
      ["asc"]
    )
  );

  return (
    <div
      key={`activity-${data.date}-${data.id}`}
      className={
        embedded
          ? "p-3 bg-white border-bottom activity position-relative"
          : "p-3 bg-white rounded mb-3 activity position-relative"
      }
    >
      {disabled || bulkUpdate || (
        <Dropdown>
          <Dropdown.Toggle id="activity-settings" variant="white">
            <FontAwesomeIcon icon={faEllipsisVertical} fixedWidth />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {/*<Dropdown.Item>
              <FontAwesomeIcon icon={faArrowUp} fixedWidth /> Nach oben
            </Dropdown.Item>
            <Dropdown.Item>
              <FontAwesomeIcon icon={faArrowDown} fixedWidth /> Nach unten
            </Dropdown.Item>
            <Dropdown.Divider />*/}
            <Dropdown.Item
              className="text-danger"
              onClick={(e) => {
                e.preventDefault();
                onRemove(id);
              }}
            >
              <FontAwesomeIcon icon={faTrash} fixedWidth /> Löschen
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}

      <Row className="g-2 mt-2">
        <Col xs={12} sm={bulkUpdate ? 6 : 5} md={bulkUpdate ? 6 : 4}>
          <Form.Label>
            <small>Projekt</small>
          </Form.Label>
          <Select
            isDisabled={disabled}
            value={_.find(projectOptions, { value: project })}
            placeholder="Bitte wählen…"
            onChange={({ value }) => {
              const newProjectId = parseInt(value);
              // Allow old project to be shown but do not allow to update it.
              if (newProjectId !== 0 && newProjectId !== project) {
                onUpdate({
                  ...data,
                  activityType: null,
                  project: newProjectId,
                });
              }
            }}
            options={projectOptions}
          />
        </Col>

        <Col xs={7} sm={bulkUpdate ? 6 : 4} md={bulkUpdate ? 6 : 4}>
          <Form.Label>
            <small>Tätigkeit</small>
          </Form.Label>
          <Select
            isDisabled={disabled}
            placeholder="Bitte wählen…"
            value={
              _.find(activityOptions, { value: activityType }) || {
                value: "0",
                label: "Sonstige",
              }
            }
            options={activityOptions}
            onChange={({ value }) => {
              onUpdate({
                ...data,
                activityType: parseInt(value) === 0 ? null : parseInt(value),
              });
            }}
          />
        </Col>

        {bulkUpdate || (
          <Col xs={5} sm={3} md={4}>
            <Form.Label>
              <small>Dauer</small>
            </Form.Label>

            {disabled ? (
              <Form.Control
                disabled={true}
                type="text"
                value={moment
                  .duration(duration, "m")
                  .format("h:mm", { trim: false })}
              ></Form.Control>
            ) : (
              <DurationPicker
                value={duration}
                key={`${data.date}-duration-${data.id}`}
                onChange={(newDuration) => {
                  onUpdate({
                    ...data,
                    duration: newDuration,
                  });
                }}
                maxHours={24}
              />
            )}
          </Col>
        )}
      </Row>

      {/*{(project !== null && !activityType) && */}
      <Form.Group
        className="mt-3"
        onFocus={(e) => {
          e.preventDefault();
          setSelected(true);
        }}
        onBlur={(e) => {
          e.preventDefault();
          setSelected(false);
        }}
      >
        <Form.Label>
          <small>Bauteil/Bemerkung</small>
        </Form.Label>
        <Form.Control
          disabled={disabled}
          as="textarea"
          value={note}
          onChange={(e) => {
            e.preventDefault();
            onUpdate({
              ...data,
              note: e.target.value,
            });
          }}
          rows={selected ? 5 : 2}
          className={selected ? "active" : ""}
        />
      </Form.Group>
    </div>
  );
};

const dateFormat = "dd, DD.MM.YY";

const dateToString = (date) => {
  const dateObject = moment(date, dateFormat);

  if (dateObject.isSame(moment().subtract(1, "day"), "day")) {
    return "Gestern";
  }

  if (dateObject.isSame(moment(), "day")) {
    return "Heute";
  }

  if (dateObject.isSame(moment().add(1, "day"), "day")) {
    return "Morgen";
  }

  return dateObject.format(dateFormat);
};

const Tracking = ({ embedded, employeeId }) => {
  const [saving, setSaving] = useState(false);
  const [date, setDate] = useState(config.today);
  const [activities, setActivities] = useState(undefined);
  const network = useNetworkState();

  const disableTracking =
    getPermission("employee", "change") === false &&
    moment(date, dateFormat).diff(moment(config.today, dateFormat), "days") <
      -1 * parseInt(config.disable_employee_tracking_after_days);

  const [
    savedActivities,
    activitiesTotalCount,
    searchParams,
    setSearchParams,
  ] = useMany("activity", {
    additionalSearchParams: {
      date: moment(date, dateFormat).format("YYYY-MM-DD"),
      employee: employeeId || config.user_id,
    },
  });

  useEffect(() => {
    setSearchParams({
      additionalSearchParams: {
        date: moment(date, dateFormat).format("YYYY-MM-DD"),
        employee: employeeId || config.user_id,
      },
    });
  }, [date]);

  useEffect(() => {
    api
      .get()
      .then(() => {})
      .catch((e) => {
        alert(
          "Keine Internetverbindung, Zeiteinträge werden evenutell nicht mehr gespeichert."
        );
      });
  }, [network]);

  // Set initial activities
  useEffect(() => {
    setActivities(savedActivities);
  }, [savedActivities, date]);

  const addActivity = () => {
    setSaving(true);
    api
      .post("activity", {
        date: moment(date, dateFormat).format("YYYY-MM-DD"),
        employee: employeeId || config.user_id,
      })
      .then(({ data }) => {
        setSaving(false);
        setActivities(activities.concat(data));
      });
  };

  const removeActivity = (id) => {
    setSaving(true);
    api.delete(`activity/${id}`).then(() => {
      setSaving(false);
      setActivities(activities.filter((activity) => activity.id !== id));
    });
  };

  const updateActivity = (updatedActivity) => {
    setSaving(true);
    setActivities(
      activities.map((activity) =>
        activity.id === updatedActivity.id ? updatedActivity : activity
      )
    );
    api.patch(`activity/${updatedActivity.id}`, updatedActivity).then(() => {
      setSaving(false);
    });
  };

  const durationToday = (activities || [])
    .map((activity) =>
      parseInt(
        moment
          .duration(activity.duration, "d h:mm:ss")
          .format("mm", 0)
          .replaceAll(".", "")
      )
    )
    .reduce((x, y) => x + y, 0);

  return (
    <div id="tracking">
      {
        <div
          style={
            embedded
              ? {}
              : {
                  position: "fixed",
                  left: 0,
                  top: 0,
                  width: "100%",
                  zIndex: 100,
                }
          }
        >
          <div
            className={
              embedded
                ? "w-100 d-flex bg-light justify-content-around py-0 text-dark"
                : "w-100 d-flex bg-dark justify-content-around py-0 text-white"
            }
          >
            <button
              className={
                embedded
                  ? "btn btn-sm py-2 btn-light rounded-0"
                  : "btn btn-sm py-2 btn-dark rounded-0"
              }
              style={{ width: "35%" }}
              onClick={(e) => {
                e.preventDefault();
                setDate(
                  moment(date, dateFormat)
                    .subtract(1, "week")
                    .format(dateFormat)
                );
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
            </button>
            <span className={embedded ? "h4 mb-0 py-1" : "h2 mb-0 py-1"}>
              KW {moment(date, dateFormat).subtract(1, "day").week()}
            </span>
            <button
              className={
                embedded
                  ? "btn btn-sm py-2 btn-light rounded-0"
                  : "btn btn-sm py-2 btn-dark rounded-0"
              }
              style={{ width: "35%" }}
              onClick={(e) => {
                setDate(
                  moment(date, dateFormat).add(1, "week").format(dateFormat)
                );
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} fixedWidth />
            </button>
          </div>

          <div className="w-100 d-flex bg-secondary justify-content-around">
            <button
              className="btn btn-sm py-2 btn-secondary rounded-0"
              style={{ width: "30%" }}
              onClick={(e) => {
                e.preventDefault();
                setDate(
                  moment(date, dateFormat).subtract(1, "day").format(dateFormat)
                );
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} fixedWidth size="lg" />
            </button>
            <div
              className={
                embedded
                  ? "h4 px-4 py-2 mb-0 text-center"
                  : "h3 px-4 py-2 mb-0 text-center"
              }
              style={{ width: "60%" }}
            >
              <div>{dateToString(date)} </div>
              <small className="ms-3">
                <small>
                  (bisher:{" "}
                  {moment
                    .duration(durationToday, "m")
                    .format("h:mm[h]", { trim: false })}
                  )
                </small>
              </small>
            </div>
            <button
              className="btn btn-sm py-2 btn-secondary rounded-0"
              style={{ width: "30%" }}
              onClick={(e) => {
                setDate(
                  moment(date, dateFormat).add(1, "day").format(dateFormat)
                );
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} fixedWidth size="lg" />
            </button>
          </div>
          <div
            className={
              embedded
                ? "bg-white text-end pe-5 pt-1"
                : "bg-light text-end pe-5 pt-1"
            }
            style={{ height: "27px" }}
          >
            {saving ? (
              <>
                <small className="pe-2 text-danger">Aktualisiere </small>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g className="spinner_Wezc">
                    <circle cx="12" cy="2.5" r="1.5" opacity=".14" />
                    <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                    <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                    <circle cx="21.50" cy="12.00" r="1.5" opacity=".57" />
                    <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                    <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                    <circle cx="12" cy="21.5" r="1.5" />
                  </g>
                </svg>
              </>
            ) : (
              <small className="text-success">Gespeichert</small>
            )}
          </div>
        </div>
      }

      <div
        className={embedded || "px-2"}
        style={{ paddingTop: embedded ? 0 : 140 }}
        key={date}
      >
        {activities && activities.length > 0 ? (
          activities.map((activity, index) => (
            <Activity
              data={activity}
              onRemove={removeActivity}
              onUpdate={updateActivity}
              embedded={embedded}
              key={`${date}-${index}`}
              disabled={disableTracking}
            />
          ))
        ) : (
          <div className="text-center p-2">
            <small>Bisher keine Arbeitszeiten erfasst</small>
          </div>
        )}
      </div>
      {embedded ? (
        <button
          className="btn btn-sm btn-warning rounded-0 w-100"
          onClick={(e) => {
            e.preventDefault();
            addActivity();
          }}
        >
          Eintrag hinzufügen <FontAwesomeIcon icon={faPlus} fixedWidth />
        </button>
      ) : (
        <>
          {disableTracking || (
            <button
              className="d-inline-block p-4 rounded-circle bg-warning border border-2 border-secondary shadow-lg"
              style={{
                width: "72px",
                height: "72px",
                position: "fixed",
                right: "30px",
                bottom: "30px",
              }}
              onClick={(e) => {
                e.preventDefault();
                addActivity();
              }}
            >
              <FontAwesomeIcon icon={faPlus} fixedWidth />
            </button>
          )}
          <button
            className="d-inline-block p-4 rounded-circle bg-white border border-2 border-secondary shadow-lg"
            style={{
              width: "72px",
              height: "72px",
              position: "fixed",
              left: "30px",
              bottom: "30px",
            }}
            data-bs-toggle="modal"
            data-bs-target="#signOutConfirmModal"
          >
            <FontAwesomeIcon icon={faRightFromBracket} fixedWidth />
          </button>
        </>
      )}
    </div>
  );
};

export default Tracking;
