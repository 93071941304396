import config from "../util/config";

const getPermission = (model, permissionType) => {
  if (model === undefined || permissionType === undefined) {
    return false;
  }

  return (
    config.perms.indexOf(
      `flammersberger_tracking_rest_api.${permissionType.toLowerCase()}_${model.toLowerCase()}`
    ) !== -1
  );
};

export default getPermission;
