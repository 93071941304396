import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import c from "classnames";
import equals from "array-equal";
// import { parse } from "json2csv";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faColumns,
  faCheck,
  faTimes,
  faSearch,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import { DeleteModal } from "./deleteModal.js";

import { useMany, deleteMany, getPermission } from "../../hooks";

const PerPage = ({ searchParams, setSearchParams }) => {
  return (
    <div className="form-group">
      <div className="dropup">
        <button
          className="btn btn-sm btn-outline-secondary dropdown-toggle"
          data-bs-toggle="dropdown"
        >
          {searchParams.perPage}
        </button>
        <div className="dropdown-menu" style={{ minWidth: "50px" }}>
          <button
            className="dropdown-item btn btn-outline-white"
            style={{ padding: "0.25rem 0.75rem" }}
            onClick={() => {
              setSearchParams({
                ...searchParams,
                perPage: 5,
              });
            }}
          >
            5
          </button>
          <button
            className="dropdown-item btn btn-outline-white"
            style={{ padding: "0.25rem 0.75rem" }}
            onClick={() => {
              setSearchParams({
                ...searchParams,
                perPage: 10,
              });
            }}
          >
            10
          </button>
          <button
            className="dropdown-item btn btn-outline-white"
            style={{ padding: "0.25rem 0.75rem" }}
            onClick={() => {
              setSearchParams({
                ...searchParams,
                perPage: 20,
              });
            }}
          >
            20
          </button>
          <button
            className="dropdown-item btn btn-outline-white"
            style={{ padding: "0.25rem 0.75rem" }}
            onClick={() => {
              setSearchParams({
                ...searchParams,
                perPage: 50,
              });
            }}
          >
            50
          </button>
          <button
            className="dropdown-item btn btn-outline-white"
            style={{ padding: "0.25rem 0.75rem" }}
            onClick={() => {
              setSearchParams({
                ...searchParams,
                perPage: 100,
              });
            }}
          >
            100
          </button>
        </div>
      </div>
    </div>
  );
};

const Pagination = ({ searchParams, setSearchParams, totalPages }) => {
  const { page } = searchParams;
  return (
    <nav>
      <ul className="pagination justify-content-end">
        <li className="page-item">
          <button
            className="btn btn-sm btn-outline-secondary mx-1"
            onClick={() => {
              setSearchParams({
                ...searchParams,
                page: Math.max(page - 1, 0),
              });
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} /> Vorherige Seite
          </button>
        </li>
        {new Array(totalPages).fill(0).map((p, index) => {
          const pageVisible = (p) =>
            index <= 1 ||
            index > totalPages - 3 ||
            index === p ||
            index === p - 1 ||
            index === p + 1;
          if (!pageVisible(page)) {
            if (pageVisible(page - 1) || pageVisible(page + 1)) {
              return (
                <li className="page-item" key={index}>
                  <button className="btn btn-sm btn-outline-white mx-1">
                    ...
                  </button>
                </li>
              );
            } else {
              return null;
            }
          }
          return (
            <li className="page-item" key={index}>
              <button
                className={c(
                  "btn btn-sm",
                  index === page ? "btn-secondary" : "btn-outline-secondary",
                  "mx-1"
                )}
                onClick={() => {
                  setSearchParams({
                    ...searchParams,
                    page: index,
                  });
                }}
              >
                {index + 1}
              </button>
            </li>
          );
        })}
        <li className="page-item">
          <button
            className="btn btn-sm btn-outline-secondary mx-1"
            onClick={() => {
              setSearchParams({
                ...searchParams,
                page: Math.min(page + 1, totalPages - 1),
              });
            }}
          >
            Nächste Seite <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </li>
      </ul>
    </nav>
  );
};

const ToggleColumns = ({ columns, toggleColumn, hiddenColumns }) => {
  return (
    <div className="form-group ms-2">
      <div className="dropup">
        <button
          className="btn btn-sm btn-outline-secondary dropdown-toggle"
          data-bs-toggle="dropdown"
        >
          <FontAwesomeIcon icon={faColumns} />
        </button>
        <div className="dropdown-menu" style={{ minWidth: "50px" }}>
          {Object.keys(columns).map((columnKey, index) => (
            <div key={`toggle-column-${index}`}>
              <button
                className="dropdown-item btn btn-outline-white"
                style={{ padding: "0.25rem 0.75rem" }}
                onClick={() => {
                  toggleColumn(columnKey);
                }}
              >
                <FontAwesomeIcon
                  size="xs"
                  icon={faCheck}
                  style={{
                    opacity:
                      hiddenColumns.indexOf(columnKey) === -1 ? "1" : "0",
                  }}
                />{" "}
                {columns[columnKey]}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// const Export = (props) => {
//   const { type, additionalSearchParams, resourceTitle } = props;

//   const downloadToFile = (content, filename, contentType) => {
//     const a = document.createElement("a");
//     const file = new Blob([content], { type: contentType });

//     a.href = URL.createObjectURL(file);
//     a.download = filename;
//     a.click();

//     URL.revokeObjectURL(a.href);
//   };

//   const searchParams = {
//     page: 0,
//     perPage: 0,
//     order: -1,
//     orderBy: "updatedAt",
//     additionalSearchParams,
//   };

//   return (
//     <div className="form-group ms-2">
//       <div className="dropup">
//         <button
//           className="btn btn-sm btn-outline-secondary"
//           onClick={() => {
//             getMany(type, searchParams).then(([data, count]) => {
//               const csv = parse(data, { fields: Object.keys(data[0]) });
//               downloadToFile(csv, `${resourceTitle}.csv`, "text/plain");
//             });
//           }}
//         >
//           Exportieren <i className="fa fa-download" />
//         </button>
//       </div>
//     </div>
//   );
// };

const Table = (props) => {
  const {
    singleRoute,
    resourceTitle,
    columns,
    columnsWidth,
    type,
    embedded,
    compact,
    noSearch,
    noFooter,
    additionalSearchParams,
    mapEntries,
    onSelectedIds,
    onSingleSelect,
    additionalActions,
  } = props;
  const [selectedIds, setSelectedIds] = useState(props.selectedIds || []);
  const navigate = useNavigate();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [data, count, searchParams, setSearchParams, loading] = useMany(
    type,
    (additionalSearchParams || props.perPage) && {
      additionalSearchParams,
      perPage: props.perPage || 10,
      order: props.order || -1,
      orderBy: props.orderBy || "updatedAt",
    }
  );

  const { page, perPage } = searchParams;

  const totalPages = parseInt(Math.ceil(count / perPage));

  const toggleSelectedIds = (ids) => {
    let selectedIdsCopy = [...selectedIds];
    ids.forEach((id) => {
      const entryIndex = selectedIdsCopy.indexOf(id);
      if (entryIndex !== -1) {
        selectedIdsCopy.splice(entryIndex, 1);
      } else {
        selectedIdsCopy.push(id);
      }
    });
    setSelectedIds(selectedIdsCopy);
    if (onSelectedIds) {
      onSelectedIds(selectedIdsCopy);
    }
  };

  useEffect(() => {
    if (props.selectedIds && !equals(props.selectedIds, selectedIds)) {
      setSelectedIds(props.selectedIds);
    }
  }, [props.selectedIds, selectedIds]);

  // useEffect(() => {
  //   console.log(selectedIds)
  //   if(onSelectedIds) {
  //     onSelectedIds(selectedIds);
  //   }
  // }, [selectedIds]);

  const selectedAll =
    data.every((entry) => selectedIds.indexOf(entry.id) !== -1) &&
    selectedIds.length > 0;

  const canDelete = getPermission(type, "delete");
  const canAdd = getPermission(type, "add");

  return (
    <>
      <DeleteModal
        count={selectedIds.length}
        onConfirm={async () => {
          await deleteMany(type, selectedIds);
          // location.go(0);
          navigate(0);
        }}
      />
      <div className="w-100 resources-table-actions">
        <div className="text-right pb-1">
          {!noSearch && (
            <>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className={c("form-control", "search-filled")}
                  placeholder="Suche"
                  value={searchInputValue}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setSearchParams({
                        ...searchParams,
                        page: 0,
                        search: searchInputValue,
                      });
                    }

                    if (e.key === "Escape") {
                      setSearchInputValue("");
                      setSearchParams({
                        ...searchParams,
                        page: 0,
                        search: "",
                      });
                    }
                  }}
                  onChange={(e) => {
                    setSearchInputValue(e.target.value);
                    if (e.target.value.trim() === "") {
                      setSearchParams({
                        ...searchParams,
                        page: 0,
                        search: "",
                      });
                    }
                  }}
                />

                <button
                  className="btn btn-link search-clear"
                  onClick={() => {
                    setSearchInputValue("");
                    setSearchParams({
                      ...searchParams,
                      search: undefined,
                    });
                  }}
                  style={{ width: "40px" }}
                >
                  {searchInputValue && <FontAwesomeIcon icon={faTimes} />}
                </button>

                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    setSearchParams({
                      ...searchParams,
                      page: 0,
                      search: searchInputValue,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </button>
                {!embedded && (
                  <>
                    {additionalActions}{" "}
                    {selectedIds.length > 0
                      ? canDelete && (
                          <button
                            className="btn btn-sm btn-danger border-start ms-2"
                            data-bs-toggle="modal"
                            data-bs-target="#deleteConfirmModal"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />{" "}
                            {selectedIds.length}{" "}
                            {selectedIds.length > 1 ? "Einträge" : "Eintrag"}
                            löschen
                          </button>
                        )
                      : canAdd && (
                          <button
                            className="btn btn-sm btn-secondary border-start ms-2"
                            onClick={() => navigate(singleRoute)}
                          >
                            <FontAwesomeIcon icon={faPlus} /> {resourceTitle}
                          </button>
                        )}
                  </>
                )}
              </div>
            </>
          )}
        </div>

        <div
          className="table-responsive resources-table"
          style={{
            minHeight: compact ? "460px" : "auto",
            filter: loading ? "blur(5px)" : "none",
            pointerEvents: loading ? "none" : "auto",
          }}
          disabled={loading}
        >
          <table
            className="table table-hover table-striped border-white"
            style={{ width: "100%" }}
          >
            {/*<colgroup>
            <col span="1" style={{
              width: "5%"
            }}/>
            {Object.keys(columns).map((column, index) => (
              <col key={index} span="1" style={
                columnsWidth &&
                columnsWidth[column] && {
                  width: columnsWidth[column]
                }
              }/>
            ))}
          </colgroup>*/}
            <thead>
              <tr>
                {!onSingleSelect && (
                  <th
                    scope="col"
                    className="border-top-0"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSelectedIds(
                        data
                          .filter((entry) => {
                            return selectedAll
                              ? selectedIds.indexOf(entry.id) !== -1
                              : selectedIds.indexOf(entry.id) === -1;
                          })
                          .map((entry) => entry.id)
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={selectedAll}
                        style={{ cursor: "pointer" }}
                        onChange={() => {}}
                      />
                    </div>
                  </th>
                )}
                {columns &&
                  Object.keys(columns)
                    .filter((colKey) => hiddenColumns.indexOf(colKey) === -1)
                    .map((columnKey, index) => (
                      <th
                        scope="col"
                        className="user-select-none border-top-0"
                        key={index}
                        onClick={() => {
                          if (
                            searchInputValue ||
                            (additionalSearchParams || {}).ordering
                          ) {
                            return;
                          }
                          setSearchParams({
                            ...searchParams,
                            orderBy: columnKey,
                            order: searchParams.order > 0 ? -1 : 1,
                          });
                        }}
                        style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                      >
                        {columns[columnKey]}{" "}
                        {!searchInputValue &&
                          searchParams.orderBy === columnKey && (
                            <FontAwesomeIcon
                              icon={
                                searchParams.order > 0
                                  ? faChevronDown
                                  : faChevronUp
                              }
                            />
                          )}
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((entry, index) => (
                  <tr
                    key={`p${page}-i${index}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (singleRoute) {
                        navigate(`${singleRoute}/${entry.id}`);
                      } else {
                        toggleSelectedIds([entry.id]);
                        if (onSingleSelect) {
                          onSingleSelect(entry.id, entry);
                        }
                      }
                    }}
                  >
                    {!onSingleSelect && (
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleSelectedIds([entry.id]);
                        }}
                      >
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={selectedIds.indexOf(entry.id) !== -1}
                            onChange={() => {}}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    )}
                    {Object.keys(columns)
                      .filter((colKey) => hiddenColumns.indexOf(colKey) === -1)
                      .map((column, index2) => (
                        <td key={`p${page}-i${index}-ii${index2}`}>
                          <div
                            className="table-cell"
                            style={
                              columnsWidth &&
                              columnsWidth[column] && {
                                width: columnsWidth[column],
                              }
                            }
                          >
                            {mapEntries && mapEntries[column]
                              ? mapEntries[column](entry)
                              : entry[column]}
                          </div>
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {!noFooter && (
          <div className="d-flex justify-content-between">
            <>
              <div className="d-flex">
                <PerPage
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                />
                <ToggleColumns
                  columns={columns}
                  toggleColumn={(column) => {
                    setHiddenColumns(
                      hiddenColumns.indexOf(column) === -1
                        ? hiddenColumns.concat([column])
                        : hiddenColumns.filter((colKey) => colKey !== column)
                    );
                  }}
                  hiddenColumns={hiddenColumns}
                />
                {/*<Export {...props} />*/}
              </div>
              {totalPages > 1 && (
                <Pagination
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  totalPages={totalPages}
                />
              )}
            </>
          </div>
        )}
      </div>
    </>
  );
};

export default Table;
